<template>
  <div>
    <headers />
    <div class="page page_one">
      <div class="content">
        <div class="introduce-title">淄博博山连顺汽车附件有限公司</div>
        <div class="flex-content">
          <img
            class="videoImg"
            :src="require('@/assets/image/customer/content1.png')"
            alt=""
          />
          <div class="introduce-con">
            <p class="introduce">
              博山某汽车附件有限公司是导向臂衬套系列、吊杆、稳定杆衬套系列、
              矿山设备、重卡开口套系列、钢板弹簧衬套系列等产品专业生产加工的公司。
            </p>
            <p class="introduce">
              数壤实施团队于2022年6月，在公司进行了企业基本信息调研、现场用
              电勘察、设计实施方案、现场实施及培训答疑，两天半的时间实现了从
              企业调研到公共服务平台上线实施试运行。之后也建立有效的沟通工作组群，在群内与企业进行有效及时的互动，积极为企业解答平台运行过程中遇到的操作及多种功能实现的问题。
            </p>
            <p class="introduce">
              经两个月的平台运行，切实为企业在生产效率提高和能源合理利用及节省方面带来有效改善。此外，企业在基础能源模块上线后，充分了解了当前用电峰平谷的规则，根据不同时段的电价差异合理的调整自有生产计划及特殊工序工作时段，使用能成本得到了有效的改善节省。
            </p>
          </div>
        </div>
      </div>
      <div class="customer_per">
        <div class="customer_per_title">平台实施效果</div>
        <div class="customer_per_range">
          <div class="one">
            <div class="up-name">人员效率</div>
            <div class="up-line">
              <div class="progress" style="width: 10%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="10" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">设备利用效率</div>
            <div class="up-line">
              <div class="progress" style="width: 10%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="10" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">用能效率</div>
            <div class="up-line">
              <div class="progress" style="width: 25%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="25" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">生产综合效率</div>
            <div class="up-line">
              <div class="progress" style="width: 15%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="15" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">产品一次通过率</div>
            <div class="up-line">
              <div class="progress" style="width: 5%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="5" :duration="4000" />%
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <div class="introduce-title">淄博纳仕福机械制造有限公司</div>
        <div class="flex-content">
          <img
            class="cont_2Img"
            :src="require('@/assets/image/customer/content2.png')"
            alt=""
          />
          <div class="introduce-con">
            <p class="introduce">
              数壤实施团队于2022年6月在公司现场充分了解企业组织
              架构及应用平台人员信息、勘察配电柜走线排布，提前做好公共服务平台的系统用户预配置。并于6.23早上工厂正式开工上班前到现场完成了能源智能采集终端的并线安装，现场完成硬件的配置调试。同时，组织企业生产相关管理层及班组长集中培训、认识服务平台制造模块的功能及特点优势。实施制造管理模块，教授生产工单实际录入，以实例引导操作员工参与培训，进行工艺SOP、工艺流程模板的创建，试行报工操作，熟悉整个工单流程。了解企业人才需求信息，在服务平台人才中心模块协助企业提交所需人才的具体需求。利用上午半天的时间，基本实现了BOSS中心、人才中心、制造管理和能源管理的上线实施运行。
            </p>
            <p class="introduce">
              至今运行两月多的时间，企业反馈运行良好。企业负责人反应公共服务平台解决了之前生产工单过程不透明、工单迟交的诸多问题，在平台制造管理和能源管理模块的支持下，不仅实现了生产效率25%左右的提升，也达到了用电节省近30%的效果。
            </p>
          </div>
        </div>
      </div>
      <div class="customer_per">
        <div class="customer_per_title">平台实施效果</div>
        <div class="customer_per_range">
          <div class="one">
            <div class="up-name">人员效率</div>
            <div class="up-line">
              <div class="progress" style="width: 8%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="8" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">设备利用效率</div>
            <div class="up-line">
              <div class="progress" style="width: 12%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="12" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">用能效率</div>
            <div class="up-line">
              <div class="progress" style="width: 30%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="30" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">生产综合效率</div>
            <div class="up-line">
              <div class="progress" style="width: 17%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="17" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">产品一次通过率</div>
            <div class="up-line">
              <div class="progress" style="width: 6%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="6" :duration="4000" />%
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <div class="introduce-title">淄博鲁明泵业</div>
        <div class="flex-content">
          <img
            class="videoImg"
            :src="require('@/assets/image/customer/content3.png')"
            alt=""
          />
          <div class="introduce-con">
            <p class="introduce">
              淄博鲁明泵业有限公司位于山东—博山，企业是集研发、生产、销售于一体的泵类产品制造类企业。以屏蔽泵、磁力泵、氟塑料泵等为主要产品。该公司是典型的泵类企业，该类企业具有产品种类众多，零部件变化频繁，工艺差别巨大，产品设计不断更新的特点。
            </p>
            <p class="introduce">
              数壤平台以轻量化契合泵类企业的生产特点，在工艺变化的灵活、工序变化的多样、工艺修改的操作便捷等等方面与泵类企业完美契合，做到为泵类企业减负、降本、增效，同时开启数壤商城的优品售卖，通过专业的工业品电商帮助拓宽渠道。
            </p>
          </div>
        </div>
      </div>
      <div class="customer_per">
        <div class="customer_per_title">平台实施效果</div>
        <div class="customer_per_range">
          <div class="one">
            <div class="up-name">人员效率</div>
            <div class="up-line">
              <div class="progress" style="width: 10%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="10" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">设备利用效率</div>
            <div class="up-line">
              <div class="progress" style="width: 10%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="10" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">用能效率</div>
            <div class="up-line">
              <div class="progress" style="width: 15%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="15" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">生产综合效率</div>
            <div class="up-line">
              <div class="progress" style="width: 15%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="15" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">产品销售量</div>
            <div class="up-line">
              <div class="progress" style="width: 15%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="15" :duration="4000" />%
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <div class="introduce-title">潍坊胜利石化机械有限公司</div>
        <div class="flex-content">
          <img
            class="videoImg"
            :src="require('@/assets/image/customer/content4.png')"
            alt=""
          />
          <div class="introduce-con">
            <p class="introduce">
              公司主要生产销售石油机械、化工机械，承揽一、二类压力容器等机械设备配件的设计、测绘及加工制
              造任务。主要产品有3ZB、5ZB高压柱塞注水泵系列；3ZB/JN节能型高压柱塞注水泵；3ZCB160、5ZCB127供水
              掺水泵；3ZY液力平衡增压泵、ZSDB新型往复泵、增压泵系列；3ZJB注聚合物泵系列；FQNB型撬装泥浆泵系
              列等，形成高压泵产品系列，广泛应用于石油、化工、水力、电力等工业领域。
            </p>
            <p class="introduce">
              数壤通过上线平台、软硬件设备实施，快速实现工业研发、生产制造、能源和设备管理等全链条数字化升级，降本增效。
            </p>
          </div>
        </div>
      </div>
      <div class="customer_per">
        <div class="customer_per_title">平台实施效果</div>
        <div class="customer_per_range">
          <div class="one">
            <div class="up-name">人员效率</div>
            <div class="up-line">
              <div class="progress" style="width: 9%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="9" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">设备利用效率</div>
            <div class="up-line">
              <div class="progress" style="width: 15%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="15" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">用能效率</div>
            <div class="up-line">
              <div class="progress" style="width: 20%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="20" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">生产综合效率</div>
            <div class="up-line">
              <div class="progress" style="width: 16%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="16" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">产品一次通过率</div>
            <div class="up-line">
              <div class="progress" style="width: 6%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="6" :duration="4000" />%
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <div class="introduce-title">青岛青铸装备有限公司</div>
        <div class="flex-content">
          <img
            class="videoImg"
            :src="require('@/assets/image/customer/content5.png')"
            alt=""
          />
          <div class="introduce-con">
            <p class="introduce">
              公司成立于2014年，主营铸造机械设备的研发、设计、生产，可提供覆盖铸造工厂全部工部的整体解决方案，拥有潮模砂、自硬砂铸造以及抛喷丸清理、除尘装备的自有知识产权和先进技术，可以提供覆盖铸造工厂全部工部的整体解决方案。
            </p>
            <p class="introduce">
              2021年底，数壤团队与青铸集团及装备公司研发、生产、财务等部门多次交流，分别从研发管理PLM以及生产制造管理模块进行深入探讨合作，协助应用研发图文档管理、项目管理、依图纸自动生成BOM、BOM一键转工单、在产工单及报工统计、外协件管理等多项迫切需求，理顺公司研发与生产部门独立业务管理以及联动功能，极大提高公司协作运营效率。同时，数壤与青铸在铸机设备后市场服务领域有较深的合作意向，双方协商开发专属铸机设备领域的产业大脑系统，用于公司销售到市场面上的设备远程管理及维护，同时提升其自身设备在市场的竞争力。
            </p>
          </div>
        </div>
      </div>
      <div class="customer_per">
        <div class="customer_per_title">平台实施效果</div>
        <div class="customer_per_range">
          <div class="one">
            <div class="up-name">人员效率</div>
            <div class="up-line">
              <div class="progress" style="width: 15%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="15" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">设备利用效率</div>
            <div class="up-line">
              <div class="progress" style="width: 12%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="12" :duration="4000" />%
            </div>
          </div>

          <div class="one">
            <div class="up-name">生产综合效率</div>
            <div class="up-line">
              <div class="progress" style="width: 15%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="15" :duration="4000" />%
            </div>
          </div>
          <div class="one">
            <div class="up-name">产品销售量</div>
            <div class="up-line">
              <div class="progress" style="width: 15%">
                <div class="progress-bg"></div>
              </div>
            </div>
            <div class="up-num">
              <i class="el-icon-top add"></i>
              <CountTo :startVal="0" :endVal="15" :duration="4000" />%
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <div class="introduce-title">博众精工-苏州科技大学-数壤互联网工业平台</div>
        <div class="flex-content">
          <img
            class="videoImg"
            :src="require('@/assets/image/customer/content6.png')"
            alt=""
          />
          <div class="introduce-con">
            <p class="introduce">
              数壤团队几年来一直服务于博众精工的研发管理体系，迄今为止已经帮助博众精工完成了产品生命周期系统（PLM)的三期的研发和实施，实现了从图文档到BOM，编码管理等内容。数壤长期来和苏科大电子工程学院合作，在产学研和产教融合方面，联合企业，学校在平台上共同实施课程开发和人才定向培养。在数壤互联网工业平台上，基于数字金属服务博众精工的内容，数字金属和苏科大共同研发了一门工业互联网系统的课程，并联合博众精工，将学生的创新，实习，和企业定向培训，工业互联网平台实训等内容结合，完成了基于平台和实训，教学的校企合作项目。并通过平台，将相关课程和合作模式推广到全国高校及企业。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <div class="intro-header">博山·科技金融双碳生态平台</div>
        <div class="intro">
          <p>全国首个融合型数字化科技金融双碳产业生态公共服务体系;</p>
          <p>目前已上线企业53家,预计到2023年5月前完成服务淄博博山区1300家企业。</p>
        </div>
        <div class="cont_box">
          <div class="cont_1 box1">
            <span>定位 : </span>政府服务体系的延伸,强力拉动产业升级发展的“缆绳”
          </div>
          <div class="cont_1 box2">
            <span>目标 : </span>促进产业自身演进,推动产业进化成为“新实业”
          </div>
          <div class="cont_1 box3">
            <span>功效 : </span>服务产业“本体”,提升企业整体运营效率
          </div>
          <div class="cont_1 box4">
            <span>愿景 : </span
            >协同政府培育产业发展沃壤,强化产业细胞,将服务势能转化为效率功能
          </div>
        </div>
        <div class="cont_2">
          “政府做好指挥员、服务员、安全员、平台做好裁判员、教导员、辅导员”
        </div>
        <div class="cont_3">
          <div>
            <img
              class="cont_img"
              :src="require('@/assets/image/customer/content7.png')"
              alt=""
            />
            <div class="cont_name">企业全局规划</div>
          </div>
          <div>
            <img
              class="cont_img"
              :src="require('@/assets/image/customer/content8.png')"
              alt=""
            />
            <div class="cont_name">企业服务</div>
          </div>
          <div>
            <img
              class="cont_img"
              :src="require('@/assets/image/customer/content9.png')"
              alt=""
            />
            <div class="cont_name">产业科技赋能</div>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <div class="introduce-title">上海天地软件园智慧零碳园区平台</div>
        <div>
          <img
            class="img_sh"
            :src="require('@/assets/image/customer/content12.png')"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <div class="intro-title">四大特性: 碳家底、碳监管、碳目标、碳成效</div>
        <div class="intro_cardList">
          <div class="intro_card">
            <div class="intro_card_div">
              <img
                :src="require('@/assets/image/customer/icon1.png')"
                alt=""
                class="card_img1"
              />
            </div>
            <div class="intro_card_title">摸清碳家底</div>
            <div class="intro_card_content">
              实现双碳与两高数据汇集,摸清碳家底,帮助政府全面掌握城市和行业碳排放情况,辅助政府开展决策分析与产业指导
            </div>
          </div>

          <div class="intro_card">
            <div class="intro_card_div">
              <img
                :src="require('@/assets/image/customer/icon2.png')"
                alt=""
                class="card_img2"
              />
            </div>
            <div class="intro_card_title">推动碳监管</div>
            <div class="intro_card_content">
              实现对区域、重点排放单位及各领域的监管,支撑政府出台相关碳管控指标和奖惩措施,引导促进城市和行业有序实现碳中和
            </div>
          </div>

          <div class="intro_card">
            <div class="intro_card_div">
              <img
                :src="require('@/assets/image/customer/icon3.png')"
                alt=""
                class="card_img3"
              />
            </div>
            <div class="intro_card_title">落实碳目标</div>
            <div class="intro_card_content cont3">
              构建双控目标预警机制,辅助政府落实完成能源+双碳双控目标,推动企业整体转型升级,助力企业迎接绿色经济
            </div>
          </div>

          <div class="intro_card">
            <div class="intro_card_div">
              <img
                :src="require('@/assets/image/customer/icon4.png')"
                alt=""
                class="card_img4"
              />
            </div>
            <div class="intro_card_title">展示碳成效</div>
            <div class="intro_card_content cont4">
              展示政府在双碳政策下的治理成就,展示开展的各项示范项目及推广成效,明确绿色发展中探索出的亮点与特色
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page_last">
      <div class="content">
        <div class="introduce-title">马鞍山博望区刀模具工业大脑</div>
        <div class="page_last_flex">
          <div class="page_last_left">
            <img
              class="videoImg"
              :src="require('@/assets/image/customer/content10.png')"
              alt=""
            />
            <img
              class="videoImg"
              :src="require('@/assets/image/customer/content11.png')"
              alt=""
            />
          </div>
          <div class="introduce-con">
            <p class="introduce">
              作为本行业产业链中重要的配套服务分支,各类工具、机具的技术水平一定程度上决定了行业生产工艺的进步与生产效率的提升.博望区位于马鞍山市最东端,地处长三角经济圈和南京一小时都市圈内,与南京江宁、溧水、高淳三区接壤,素有中国“刃模具之乡”的美誉,机床及刃模具产业历来是当地的传统特色产业和主导产业。
            </p>
            <p class="introduce">
              近年来,博望区机床产业逐渐壮大,接连获得中国国家火炬计划博望折机床及刃模具特色产业基地、全国剪折机床产业和知名品牌展示范区等称号。
            </p>
            <p class="introduce">
              数壤通过服务了博望区刃模具行业的二十家企业的数字化转型,帮助他们建立起从研发到制造的全过程管控,从而把规模化的数据再次聚合赋能,通过地区和行业两个数据维度,建立了马鞍山博望区的刀模具工业大脑。
            </p>
            <p class="introduce">
              通过对地区行业企业全生命周期的数字化转型服务,综合采集企业的研发、订单、产能、供应链、质量、库存、等数据.根据行业和地域特性,全面展示地区产业发展现状,并提供针对性的预测和统计,帮助政府对产业进行引导,对企业进行有针对性的扶助。
            </p>
          </div>
        </div>
      </div>
    </div>

    <footers />
  </div>
</template>
<script>
import headers from "@/components/head";
import footers from "@/components/foot";
import CountTo from "vue-count-to";
import redirect from "@/utils/redirect";
import pcRem from "@/utils/pcrem";
export default {
  mixins: [redirect,pcRem],
  components: {
    headers,
    footers,
    CountTo,
  },
  data() {
    return {
      cardList: [
        {
          img: require("../../assets/image/customer/icon1.png"),
          title: "摸清碳家底",
          content:
            "实现双碳与两高数据汇集,摸清碳家底,帮助政府全面掌握城市和行业碳排放情况,辅助政府开展决策分析与产业指导",
        },
        {
          img: require("../../assets/image/customer/icon2.png"),
          title: "推动碳监管",
          content:
            "实现对区域、重点排放单位及各领域的监管,支撑政府出台相关碳管控指标和奖惩措施,引导促进城市和行业有序实现碳中和",
        },
        {
          img: require("../../assets/image/customer/icon3.png"),
          title: "落实碳目标",
          content:
            "构建双控目标预警机制,辅助政府落实完成能源+双碳双控目标,推动企业整体转型升级,助力企业迎接绿色经济",
        },
        {
          img: require("../../assets/image/customer/icon4.png"),
          title: "展示碳成效",
          content:
            "展示政府在双碳政策下的治理成就,展示开展的各项示范项目及推广成效,明确绿色发展中探索出的亮点与特色",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio + rem;
}

.page {
  width: 100%;
  .content {
    margin: auto;
    width: px2rem(1210);
    .introduce-title {
      position: relative;
      margin-bottom: px2rem(30);
      padding-left: px2rem(20);
      height: px2rem(45);
      font-size: px2rem(32);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: px2rem(45);
      color: rgba(0, 0, 0, 0.85);
      &::before {
        position: absolute;
        top: px2rem(1);
        left: 0;
        content: "";
        width: px2rem(5);
        height: px2rem(45);
        background: #308971;
        border-radius: px2rem(3);
      }
    }
    .flex-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: px2rem(615);
        height: px2rem(480);
      }
      .cont_2Img {
        height: px2rem(600);
      }
      .introduce-con {
        width: px2rem(560);
        .introduce {
          font-size: px2rem(22);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: px2rem(30);
        }
      }
    }
  }
  .customer_per {
    width: px2rem(1195);
    height: px2rem(237);
    background: #fff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: px2rem(10);
    margin: px2rem(30) auto px2rem(80);
    padding-top: px2rem(30);
    padding-left: px2rem(33);
    .customer_per_title {
      position: relative;
      padding-left: px2rem(20);
      height: px2rem(37);
      font-size: px2rem(26);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: px2rem(37);
      &::before {
        position: absolute;
        top: px2rem(1);
        left: 0;
        content: "";
        width: px2rem(5);
        height: px2rem(37);
        background: #308971;
        border-radius: px2rem(3);
      }
    }
    .customer_per_range {
      margin-top: px2rem(30);
      display: flex;
      flex-wrap: wrap;
      .one {
        height: px2rem(30);
        line-height: px2rem(30);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: px2rem(22);
        font-family: PingFangSC-Regular, PingFang SC;
        margin-bottom: px2rem(30);
        color: rgba(0, 0, 0, 0.65);
        margin-right: px2rem(30);
        .up-name {
          margin-right: px2rem(10);
          width: px2rem(154);
          color: rgba(0, 0, 0, 0.65);
          height: px2rem(30);
          line-height: px2rem(30);
          font-size: px2rem(22);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          text-align: right;
        }
        .up-line {
          width: px2rem(100);
          height: px2rem(10);
          background: #f5f5f5;
          border-radius: px2rem(5);
          .progress-bg {
            border-radius: px2rem(5);
            background: #f5222d;
            height: px2rem(10);
            animation: animationLine 4s forwards;
          }
          @keyframes animationLine {
            0% {
              width: 0;
            }

            100% {
              width: 100%;
            }
          }
        }
        .up-num {
          display: flex;
          align-items: center;
          width: px2rem(74);
          font-size: px2rem(22);
          font-family: HelveticaNeue;
          color: rgba(0, 0, 0, 0.65);
          line-height: px2rem(22);
          .add {
            color: #f5222d;
          }
        }
      }
    }
  }
}
.page_one {
  margin-top: px2rem(201);
}
.page_last {
  margin-bottom: px2rem(70);
}
.img_sh {
  width: px2rem(1215);
  height: px2rem(480);
}

.intro-header {
  position: relative;
  margin-top: px2rem(70);
  margin-bottom: px2rem(40);
  padding-left: px2rem(20);
  height: px2rem(45);
  font-size: px2rem(32);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: px2rem(45);
  color: rgba(0, 0, 0, 0.85);
  &::before {
    position: absolute;
    top: px2rem(1);
    left: 0;
    content: "";
    width: px2rem(5);
    height: px2rem(45);
    background: #308971;
    border-radius: px2rem(3);
  }
}
.intro {
  margin-bottom: px2rem(20);
  p {
    height: px2rem(30);
    font-size: px2rem(22);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: px2rem(30);
  }
}
.cont_box {
  display: flex;
  flex-wrap: wrap;
  .cont_1 {
    height: px2rem(40);
    border-radius: px2rem(27);
    border: px2rem(1) solid #308971;
    color: #308971;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-size: px2rem(16);
    span {
      font-weight: 600;
    }
  }
  .box1 {
    height: px2rem(22);
    line-height: px2rem(22);
    padding: px2rem(10) px2rem(39) px2rem(8) px2rem(40);
    margin-right: px2rem(20);
  }
  .box2 {
    height: px2rem(22);
    line-height: px2rem(22);
    padding: px2rem(9) px2rem(33);
  }
  .box3 {
    height: px2rem(22);
    line-height: px2rem(22);
    padding: px2rem(10) px2rem(28) px2rem(8) px2rem(27);
    margin-top: px2rem(20);
    margin-right: px2rem(20);
  }
  .box4 {
    height: px2rem(22);
    line-height: px2rem(22);
    padding: px2rem(9) px2rem(32);
    margin-top: px2rem(20);
  }
}

.cont_2 {
  margin-top: px2rem(30);
  margin-bottom: px2rem(10);
  height: px2rem(30);
  font-size: px2rem(22);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: px2rem(30);
}
.cont_3 {
  display: flex;
  text-align: center;
  margin-bottom: px2rem(80);
  .cont_img {
    width: px2rem(405);
    height: px2rem(246);
  }
  .cont_name {
    margin-top: px2rem(10);
    height: px2rem(30);
    font-size: px2rem(22);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: px2rem(30);
  }
}

.intro-title {
  margin-top: px2rem(30);
  height: px2rem(30);
  font-size: px2rem(22);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: px2rem(30);
}

.intro_cardList {
  margin-top: px2rem(16);
  display: flex;
  // justify-content: space-around;
  padding-bottom: px2rem(80);
  .intro_card {
    width: px2rem(285);
    height: px2rem(283);
    padding-bottom: px2rem(30);
    background: #fff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: px2rem(10);
    margin-right: px2rem(20);
    &:hover {
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
      transform: translate3d(0, -2px, 0);
    }
    &:last-child {
      margin-right: 0;
    }
    .intro_card_div {
      width: px2rem(80);
      height: px2rem(80);
      margin: px2rem(30) auto px2rem(20);
      background: rgba(48, 137, 113, 0.1);
      text-align: center;
      line-height: px2rem(80);
      border-radius: 50%;

      .card_img1 {
        width: px2rem(36);
        height: px2rem(36);
        margin: px2rem(2);
      }
      .card_img2 {
        width: px2rem(38);
        height: px2rem(38);
        margin: px2rem(6);
      }
      .card_img3 {
        width: px2rem(36);
        height: px2rem(35);
        margin: px2rem(-3) px2rem(2) px2rem(2);
      }
      .card_img4 {
        width: px2rem(34);
        height: px2rem(29);
        margin: px2rem(6) px2rem(3) px2rem(5);
      }
    }
    .intro_card_title {
      height: px2rem(25);
      font-size: px2rem(18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: px2rem(25);
      text-align: center;
      margin-bottom: px2rem(10);
    }
    .intro_card_content {
      width: px2rem(229);
      // height: px2rem(88);
      font-size: px2rem(16);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: px2rem(22);
      margin: 0 auto;
      text-align: center;
    }
    .cont3 {
      width: px2rem(238);
    }
    .cont4 {
      width: px2rem(245);
    }
  }
}

.page_last_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page_last_left {
    // display: flex;
    // flex-direction: column;
    width: px2rem(615);
    img {
      width: px2rem(615);
      height: px2rem(296);
    }
  }
  .introduce-con {
    width: px2rem(560);
    // height: px2rem(540);
    .introduce {
      font-size: px2rem(22);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: px2rem(30);
    }
  }
}
</style>
